#sidebar-tooltip.place-top {
  @apply !pb-3;
}

.portalModal-opening {
  animation-duration: 0.3s;
  animation-name: fadein;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
}

.portalModal-closing {
  animation-duration: 0.325s;
  animation-name: fadeout;
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}

.dm-scorebar:empty {
  @apply max-lg:hidden;
}

.animate-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.17em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.17em;
  }
}

.hexagonPulse {
  transform: scale(1);
  animation: funPulse 2s infinite;
}

@keyframes funPulse {
  0% {
    /* transform: scale(0.95); */
    filter: drop-shadow(4px 4px 10px rgba(76, 0, 151, 0));
  }

  50% {
    /* transform: scale(1); */
    /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    filter: drop-shadow(4px 4px 10px rgba(76, 0, 151, 0.5));
  }

  100% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    filter: drop-shadow(4px 4px 10px rgba(76, 0, 151, 0));
  }
}

.onboardingBounce {
  animation: bounce 2s ease infinite;
}
.onboardingBounce:hover {
  animation-play-state: paused;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-12px);
  }
}

@keyframes onboardingWiggle {
  0% {
    transform: rotate(0deg);
  }
  1.852% {
    transform: rotate(var(--wiggle-amount));
  }
  3.704% {
    transform: rotate(calc(var(--wiggle-amount) * -1));
  }
  5.555% {
    transform: rotate(var(--wiggle-amount));
  }
  7.407% {
    transform: rotate(calc(var(--wiggle-amount) * -1));
  }
  9.259% {
    transform: rotate(var(--wiggle-amount));
  }
  11.111% {
    transform: rotate(calc(var(--wiggle-amount) * -1));
  }
  12.963% {
    transform: rotate(var(--wiggle-amount));
  }
  14.815% {
    transform: rotate(calc(var(--wiggle-amount) * -1));
  }
  16.666% {
    transform: rotate(0deg);
  }
}

.onboardingWiggle {
  --wiggle-amount: 1deg;
  animation: onboardingWiggle 12s ease infinite;
}
