/* Flex Center Configurations as separate classes */
.flex-center-space-evenly-center-column {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.flex-center-center-center-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.flex-center-space-evenly-center-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.flex-center-space-between-center-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

/* Basic Footer Styles */
.footer__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.footer__container * {
    width: 92%;
    margin: 5px 0;
}

.footer__logo {
    width: 20%;
    min-width: 300px;
    text-align: center;
}

.footer__navbar {
    width: 60%;
}

.footer__nav-links {
    width: 100%;
    font-size: 18px;
}

.footer__nav-link {
    list-style: none;
    padding: 0 3%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}

.footer__nav-link a {
    font-size: 1rem;
    color: #595959;
    transition: color 0.2s ease-in;
    letter-spacing: -0.5px;
    text-decoration: none;
    white-space: nowrap;
}

.footer__nav-link a:hover,
.footer__nav-link a.active {
    color: #006d77; /* Example primary color, replace with actual */
}

.footer__bottom {
    font-weight: 500;
    margin: 10px 0;
    padding-top: 15px;
    border-top: 1px solid rgba(107, 107, 107, 0.5);
}

.footer__bottom-social {
    font-size: 28px;
    width: 80px;
    color: #313d4e;
    margin-left: 3em;
}

.footer__bottom-copyright a, .footer__bottom-copyright span {
    margin: 1em;
}

/* Media Queries for Device Width Adjustments */

/* For devices with max-width 991.98px */
@media screen and (max-width: 991.98px) {
    .footer__nav-links {
        flex-direction: column;
    }

    .footer__nav-link {
        margin: 5px 0; /* Increase spacing as elements stack vertically */
    }
}

/* For devices with max-width 767.98px */
@media screen and (max-width: 767.98px) {
    .footer__bottom {
        flex-direction: column-reverse; /* Stack copyright and social icons vertically but in reverse order */
        justify-content: center;
        padding: 5px 0; /* Add padding for better spacing */
    }

    .footer__bottom-social {
        margin-bottom: 20px; /* Add more space above the social icons */
        justify-content: center; /* Center-align the social icons within their container */
        margin: 0; /* Remove left margin to center align icons */
    }
    
    .footer__bottom a,
    .footer__bottom span {
        margin: 0 10px; /* Adjust margin for all a and span elements to fit smaller screens */
        width: fit-content; /* Ensure the width is only as wide as the content */
    }
}

/* For devices with max-width 575.98px */
@media screen and (max-width: 575.98px) {
    .footer__container {
        padding: 0 15px; /* Add padding on the sides for smaller screens */
    }

    .footer__logo {
        min-width: 250px; /* Adjust minimum width to accommodate smaller screens */
        flex-direction: column; /* Stack logo elements vertically */
        text-align: center; /* Center-align text for better appearance on small devices */
    }

    .footer__navbar {
        flex-direction: column; /* Stack navbar elements vertically */
    }

    .footer__nav-links {
        justify-content: center; /* Center-align nav-links for a tidy look */
        margin-top: 10px; /* Add space above nav-links */
    }

    .footer__nav-link a {
        font-size: 0.9rem; /* Reduce font size for links on small screens */
    }

    .footer__bottom {
        text-align: center; /* Center text for the entire bottom section */
        align-items: center; /* Align items in the center for a neat layout */
        flex-direction: column; /* Stack copyright and social elements vertically */
    }

    .footer__bottom-copyright {
    display: flex; /* Enables flexible box layout */
    flex-wrap: wrap; /* Allows items to wrap to next line as needed */
    justify-content: center; /* Adjusts the distribution of children */
    align-items: center; /* Vertically aligns items in the middle */
    text-align: center;
    }

    .footer__bottom a,
    .footer__bottom span {
        margin: 10px; /* Ensure consistent margin around links and spans */
        white-space:nowrap;
    }
}


/* Apply flex classes to appropriate elements */
/* .footer__container {
    class: flex-center-space-evenly-center-column;
}

.footer__logo, .footer__navbar {
    class: flex-center-center-center-row;
}

.footer__nav-links {
    class: flex-center-space-evenly-center-row;
}

.footer__bottom {
    class: flex-center-space-between-center-row;
} */
