#assigned-video video::-webkit-media-controls-timeline {
  display: none;
}
#assigned-video video:fullscreen::-webkit-media-controls-timeline  {
  display: block;        
}

#assigned-video iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%
}
#assigned-video input[type=range] {
  /* next line is required on Chrome but i'm not sure why / what it does */
  -webkit-appearance: none; 
  margin: 8px 0 4px 0;
  width: 100%;
}
#assigned-video input[type=range]:focus {
  outline: none;
}
#assigned-video input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-radius: 100px;
}
#assigned-video input[type=range]::-webkit-slider-thumb {
  /* box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d; */
  /* border: 0px solid #000000; */
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #078445;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}

#assigned-video input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-radius: 100px;
}
#assigned-video input[type=range]::-moz-range-thumb {
  border: 0px solid #000000;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #078445;
  cursor: pointer;
}

#assigned-video input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-radius: 100px;
  color: transparent;
}
#assigned-video input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
#assigned-video input[type=range]::-ms-fill-upper {
  background: #000000;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
#assigned-video input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 39px;
  height: 13px;
  width: 13px;
  border-radius: 7px;
  background: #078445;
  cursor: pointer;
}
#assigned-video input[type=range]:focus::-ms-fill-lower {
  background: #000000;
}
#assigned-video input[type=range]:focus::-ms-fill-upper {
  background: #000000;
}




/* html, body, #root {@apply h-full;}
body {@apply bg-gray-100;} */

.skipToMainContent {
  position: absolute;
  left: -9999em;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
}

.skipToMainContent:focus {
  background: #fff;
  font-size: 1.125rem;
  padding: 16px;
  text-align: center;
  clip: auto;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 30000;
}

/* Size Conversion breakpoints
Size   |  Bootstrap  |  Tailwind
--------------------------------
  xs   |     ---     |    ---
  sm   |     768     |    640
  md   |     992     |    768
  lg   |     1200    |    1024
  xl   |     ---     |    1280
  */

/* hide stepper arrows on input type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* SVG GRAPH STYLES */
svg {
  border: 0px gray dotted;
  /*background-color: white; broke new katex*/
  /*margin: 0px;*/
  font: 12px Georgia, Serif, Symbola;
  position: relative;
  display: inline; /* counter tailwind defaults */
}

/* .resize-katex {overflow: hidden;} */
/* .resize-katex > .katex-display {overflow: auto;} */

.canv-div-wrapper{
  text-align:center;
  @apply basis-full;
}

@media(max-width:767px){
  .canv-div-wrapper{
      margin: 0px -25px;   
  }
}

svg .grid line {
  fill: none;
  stroke: gray;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

svg .grid line {
  stroke-opacity: 0.2;
}

#mathBlock {
  font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: 1.42857143;
}

#question_page img, #problem_page img {
  display: inline;
}
/* #mathBlock .katex {
  font-size: 1.6em !important;
} */

#mathBlock .text-left .katex-display > .katex {
  text-align: left;
}
#mathBlock .text-right .katex-display > .katex {
  text-align: right;
}

/* .katex .katex-html>.newline {
  height: 12px;
} */

#problemPrompt {
  font-size: 1.3125em;
}

#problemPromptExample {
  font-size: 1.125em;
}

/* #mathBlock svg {
  font-size: .75em;
} */

.mfeField {
  display: inline-block;
  border: 1px solid #000;
  vertical-align: middle;
  padding: 5px;
  border-radius: 4px;
  min-width: 150px;
}

.answerArea .mathquill-editable, 
.answerArea .mq-editable-field,
.mathquill-editable {
    min-width: 120px;
    min-width: 20vw;
    font-size: 1em;
    max-width: 100%;
    padding: 7px 7px;
    margin-right: 0px;
    background-color: white;
    margin-bottom: 20px;
    margin-top: 10px;
}

.answerArea .mathquill-editable-small, .mathquill-editable-small {
    min-width: 30px;
    font-size: 1em;
    padding: 2px;
    background-color: white;
    text-align: center;
}

.mq-simple-ans .mq-editable-field {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
}

/* mathquill: ensure the index does not get cut off */
.mq-root-block sup {
  top: 0;
}

/* override mathquill defaults for border color to match dm color scheme */
.mq-editable-field,
.mq-math-mode .mq-editable-field {
  border: 1px solid #585858;
}

.mq-editable-field.mq-focused,
.mq-math-mode .mq-editable-field.mq-focused {
  -webkit-box-shadow: #2F52E9 0 0 1px 1px;
  -moz-box-shadow: #2F52E9 0 0 1px 1px;
  box-shadow: #2F52E9 0 0 1px 1px;
  border-color: #2F52E9;
}

svg .mq-editable-field .mq-cursor {
  position: static;
}

/* bootstrap overrides */
/* https://tools.bitfertig.de/bootstrap2tailwind/index.php */
.btn-default, .btn-default:active, .btn-default:focus{
  background-color:white;
  color: #444;
  border: 1px gray solid;
}

.btn-default:hover{
  background-color:#eee;
  color: #444;
}

.btn-default:active:hover{
  background-color:#ccc;
  color: #222;
}

.btn-default.disabled,.btn-default[disabled],fieldset[disabled] .btn-default,.btn-default.disabled:hover,.btn-default[disabled]:hover,fieldset[disabled] .btn-default:hover,.btn-default.disabled:focus,.btn-default[disabled]:focus,fieldset[disabled] .btn-default:focus,.btn-default.disabled.focus,.btn-default[disabled].focus,fieldset[disabled] .btn-default.focus,.btn-default.disabled:active,.btn-default[disabled]:active,fieldset[disabled] .btn-default:active,.btn-default.disabled.active,.btn-default[disabled].active,fieldset[disabled] .btn-default.active {
  background-color: #95a5a6;
  border-color: #95a5a6
}

.btn {
  border-width: 1px;
  @apply inline-block align-middle text-center select-none border font-normal whitespace-nowrap rounded py-1 px-3 leading-normal no-underline text-base;
}

.btn-sm, .btn-xs {
  @apply py-1 px-2 leading-tight text-xs;
}

.btn.disabled,.btn[disabled],fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none
}

/* TODO: do we need all of the following btn styles? Should we override them? */
.btn-primary {
  color: #fff;
  background-color: #2c3e50;
  border-color: #2c3e50
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #1a242f;
  border-color: #000
}

.btn-primary.active,.btn-primary:active,.btn-primary:hover,.open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #1a242f;
  border-color: #161f29
}

.btn-primary.active.focus,.btn-primary.active:focus,.btn-primary.active:hover,.btn-primary:active.focus,.btn-primary:active:focus,.btn-primary:active:hover,.open>.dropdown-toggle.btn-primary.focus,.open>.dropdown-toggle.btn-primary:focus,.open>.dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #0d1318;
  border-color: #000
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
  background-image: none
}

.btn-primary.disabled,.btn-primary.disabled.active,.btn-primary.disabled.focus,.btn-primary.disabled:active,.btn-primary.disabled:focus,.btn-primary.disabled:hover,.btn-primary[disabled],.btn-primary[disabled].active,.btn-primary[disabled].focus,.btn-primary[disabled]:active,.btn-primary[disabled]:focus,.btn-primary[disabled]:hover,fieldset[disabled] .btn-primary,fieldset[disabled] .btn-primary.active,fieldset[disabled] .btn-primary.focus,fieldset[disabled] .btn-primary:active,fieldset[disabled] .btn-primary:focus,fieldset[disabled] .btn-primary:hover {
  background-color: #2c3e50;
  border-color: #2c3e50
}

.btn-primary .badge {
  color: #2c3e50;
  background-color: #fff
}

.btn-success {
  color: #fff;
  background-color: #18bc9c;
  border-color: #18bc9c
}

.btn-success.focus,.btn-success:focus {
  color: #fff;
  background-color: #128f76;
  border-color: #0a4b3e
}

.btn-success.active,.btn-success:active,.btn-success:hover,.open>.dropdown-toggle.btn-success {
  color: #fff;
  background-color: #128f76;
  border-color: #11866f
}

.btn-success.active.focus,.btn-success.active:focus,.btn-success.active:hover,.btn-success:active.focus,.btn-success:active:focus,.btn-success:active:hover,.open>.dropdown-toggle.btn-success.focus,.open>.dropdown-toggle.btn-success:focus,.open>.dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #0e6f5c;
  border-color: #0a4b3e
}

.btn-success.active,.btn-success:active,.open>.dropdown-toggle.btn-success {
  background-image: none
}

.btn-success.disabled,.btn-success.disabled.active,.btn-success.disabled.focus,.btn-success.disabled:active,.btn-success.disabled:focus,.btn-success.disabled:hover,.btn-success[disabled],.btn-success[disabled].active,.btn-success[disabled].focus,.btn-success[disabled]:active,.btn-success[disabled]:focus,.btn-success[disabled]:hover,fieldset[disabled] .btn-success,fieldset[disabled] .btn-success.active,fieldset[disabled] .btn-success.focus,fieldset[disabled] .btn-success:active,fieldset[disabled] .btn-success:focus,fieldset[disabled] .btn-success:hover {
  background-color: #18bc9c;
  border-color: #18bc9c
}

.btn-success .badge {
  color: #18bc9c;
  background-color: #fff
}

.btn-info {
  color: #fff;
  background-color: #3498db;
  border-color: #3498db
}

.btn-info.focus,.btn-info:focus {
  color: #fff;
  background-color: #217dbb;
  border-color: #16527a
}

.btn-info.active,.btn-info:active,.btn-info:hover,.open>.dropdown-toggle.btn-info {
  color: #fff;
  background-color: #217dbb;
  border-color: #2077b2
}

.btn-info.active.focus,.btn-info.active:focus,.btn-info.active:hover,.btn-info:active.focus,.btn-info:active:focus,.btn-info:active:hover,.open>.dropdown-toggle.btn-info.focus,.open>.dropdown-toggle.btn-info:focus,.open>.dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #1c699d;
  border-color: #16527a
}

.btn-info.active,.btn-info:active,.open>.dropdown-toggle.btn-info {
  background-image: none
}

.btn-info.disabled,.btn-info.disabled.active,.btn-info.disabled.focus,.btn-info.disabled:active,.btn-info.disabled:focus,.btn-info.disabled:hover,.btn-info[disabled],.btn-info[disabled].active,.btn-info[disabled].focus,.btn-info[disabled]:active,.btn-info[disabled]:focus,.btn-info[disabled]:hover,fieldset[disabled] .btn-info,fieldset[disabled] .btn-info.active,fieldset[disabled] .btn-info.focus,fieldset[disabled] .btn-info:active,fieldset[disabled] .btn-info:focus,fieldset[disabled] .btn-info:hover {
  background-color: #3498db;
  border-color: #3498db
}

.btn-info .badge {
  color: #3498db;
  background-color: #fff
}

.btn-warning {
  color: #fff;
  background-color: #f39c12;
  border-color: #f39c12
}

.btn-warning.focus,.btn-warning:focus {
  color: #fff;
  background-color: #c87f0a;
  border-color: #7f5006
}

.btn-warning.active,.btn-warning:active,.btn-warning:hover,.open>.dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #c87f0a;
  border-color: #be780a
}

.btn-warning.active.focus,.btn-warning.active:focus,.btn-warning.active:hover,.btn-warning:active.focus,.btn-warning:active:focus,.btn-warning:active:hover,.open>.dropdown-toggle.btn-warning.focus,.open>.dropdown-toggle.btn-warning:focus,.open>.dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #a66908;
  border-color: #7f5006
}

.btn-warning.active,.btn-warning:active,.open>.dropdown-toggle.btn-warning {
  background-image: none
}

.btn-warning.disabled,.btn-warning.disabled.active,.btn-warning.disabled.focus,.btn-warning.disabled:active,.btn-warning.disabled:focus,.btn-warning.disabled:hover,.btn-warning[disabled],.btn-warning[disabled].active,.btn-warning[disabled].focus,.btn-warning[disabled]:active,.btn-warning[disabled]:focus,.btn-warning[disabled]:hover,fieldset[disabled] .btn-warning,fieldset[disabled] .btn-warning.active,fieldset[disabled] .btn-warning.focus,fieldset[disabled] .btn-warning:active,fieldset[disabled] .btn-warning:focus,fieldset[disabled] .btn-warning:hover {
  background-color: #f39c12;
  border-color: #f39c12
}

.btn-warning .badge {
  color: #f39c12;
  background-color: #fff
}

.btn-danger {
  color: #fff;
  background-color: #e74c3c;
  border-color: #e74c3c
}

.btn-danger.focus,.btn-danger:focus {
  color: #fff;
  background-color: #d62c1a;
  border-color: #921e12
}

.btn-danger.active,.btn-danger:active,.btn-danger:hover,.open>.dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #d62c1a;
  border-color: #cd2a19
}

.btn-danger.active.focus,.btn-danger.active:focus,.btn-danger.active:hover,.btn-danger:active.focus,.btn-danger:active:focus,.btn-danger:active:hover,.open>.dropdown-toggle.btn-danger.focus,.open>.dropdown-toggle.btn-danger:focus,.open>.dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #b62516;
  border-color: #921e12
}

.btn-danger.active,.btn-danger:active,.open>.dropdown-toggle.btn-danger {
  background-image: none
}

.btn-danger.disabled,.btn-danger.disabled.active,.btn-danger.disabled.focus,.btn-danger.disabled:active,.btn-danger.disabled:focus,.btn-danger.disabled:hover,.btn-danger[disabled],.btn-danger[disabled].active,.btn-danger[disabled].focus,.btn-danger[disabled]:active,.btn-danger[disabled]:focus,.btn-danger[disabled]:hover,fieldset[disabled] .btn-danger,fieldset[disabled] .btn-danger.active,fieldset[disabled] .btn-danger.focus,fieldset[disabled] .btn-danger:active,fieldset[disabled] .btn-danger:focus,fieldset[disabled] .btn-danger:hover {
  background-color: #e74c3c;
  border-color: #e74c3c
}

.btn-danger .badge {
  color: #e74c3c;
  background-color: #fff
}

.btn-group,.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
  position: relative;
  float: left
}

.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
  z-index: 2
}

.btn-group .btn+.btn,.btn-group .btn+.btn-group,.btn-group .btn-group+.btn,.btn-group .btn-group+.btn-group {
  margin-left: -1px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0
}

.btn-group>.btn:first-child {
  margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),.btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.btn-group>.btn-group {
  float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  -webkit-background-clip: padding-box;
  background-clip: padding-box
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9.5px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #7b8a8b;
  white-space: nowrap
}

.dropdown-menu>li>a:focus,.dropdown-menu>li>a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #2c3e50
}

.dropdown-menu>.active>a,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #2c3e50
}

.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
  color: #b4bcc2
}

.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed
}

.open>.dropdown-menu {
  display: block
}

.open>a {
  outline: 0
}

.dropdown-menu-right {
  left: auto;
  right: 0
}

.dropdown-menu-left {
  left: 0;
  right: auto
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #b4bcc2;
  white-space: nowrap
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret,.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
  content: ""
}

.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
      left:auto;
      right: 0
  }

  .navbar-right .dropdown-menu-left {
      left: 0;
      right: auto
  }
}

/* Overrides css rule from bootstrap-select package that applies an incorrect min-width */
.bs-container .dropdown-menu.open {
  min-width: 100%!important
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent
}

.btn .caret {
  margin-left: 0
}

.btn-lg .caret {
  border-width: 5px 5px 0
}

.row {
  /* @apply -mx-3.5; */
  width: 100%;
}

.row:before, .row:after {  
  content: " ";
  display: table;
  box-sizing: border-box;
}

.row:after {  
  clear: both
}

div.jax {
  overflow-x: visible; /* was auto */
  /* margin: -1em 0; */
}

/* TODO - removed col-xs-12 from sr only divs, test this thoroughly */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  @apply px-3.5 relative min-h-[1px];
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {@apply float-left;}
.col-xs-12 {@apply w-full; }
.col-xs-11 {@apply w-11/12; }
.col-xs-10 {@apply w-10/12; }
.col-xs-9 {@apply w-9/12; }
.col-xs-8 {@apply w-8/12; }
.col-xs-7 {@apply w-7/12; }
.col-xs-6 {@apply w-6/12; }
.col-xs-5 {@apply w-5/12; }
.col-xs-4 {@apply w-4/12; }
.col-xs-3 {@apply w-3/12; }
.col-xs-2 {@apply w-2/12; }
.col-xs-1 {@apply w-1/12; }

.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {@apply sm:float-left;}

.col-sm-12 {@apply sm:w-full; }
.col-sm-11 {@apply sm:w-11/12; }
.col-sm-10 {@apply sm:w-10/12; }
.col-sm-9 {@apply sm:w-9/12; }
.col-sm-8 {@apply sm:w-8/12; }
.col-sm-7 {@apply sm:w-7/12; }
.col-sm-6 {@apply sm:w-6/12; }
.col-sm-5 {@apply sm:w-5/12; }
.col-sm-4 {@apply sm:w-4/12; }
.col-sm-3 {@apply sm:w-3/12; }
.col-sm-2 {@apply sm:w-2/12; }
.col-sm-1 {@apply sm:w-1/12; }
.col-sm-offset-12 {@apply sm:ml-[100%]}
.col-sm-offset-11 {@apply sm:ml-[91.66666667%]}
.col-sm-offset-10 {@apply sm:ml-[83.33333333%]}
.col-sm-offset-9 {@apply sm:ml-[75%]}
.col-sm-offset-8 {@apply sm:ml-[66.66666667%]}
.col-sm-offset-7 {@apply sm:ml-[58.33333333%]}
.col-sm-offset-6 {@apply sm:ml-[50%]}
.col-sm-offset-5 {@apply sm:ml-[41.66666667%]}
.col-sm-offset-4 {@apply sm:ml-[33.33333333%]}
.col-sm-offset-3 {@apply sm:ml-[25%]}
.col-sm-offset-2 {@apply sm:ml-[16.66666667%]}
.col-sm-offset-1 {@apply sm:ml-[8.33333333%]}
.col-sm-offset-0 {@apply sm:ml-0}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {@apply md:float-left;}

.col-md-12 {@apply md:w-full; }
.col-md-11 {@apply md:w-11/12; }
.col-md-10 {@apply md:w-10/12; }
.col-md-9 {@apply md:w-9/12; }
.col-md-8 {@apply md:w-8/12; }
.col-md-7 {@apply md:w-7/12; }
.col-md-6 {@apply md:w-6/12; }
.col-md-5 {@apply md:w-5/12; }
.col-md-4 {@apply md:w-4/12; }
.col-md-3 {@apply md:w-3/12; }
.col-md-2 {@apply md:w-2/12; }
.col-md-1 {@apply md:w-1/12; }
.col-md-offset-12 {@apply md:ml-[100%]}
.col-md-offset-11 {@apply md:ml-[91.66666667%]}
.col-md-offset-10 {@apply md:ml-[83.33333333%]}
.col-md-offset-9 {@apply md:ml-[75%]}
.col-md-offset-8 {@apply md:ml-[66.66666667%]}
.col-md-offset-7 {@apply md:ml-[58.33333333%]}
.col-md-offset-6 {@apply md:ml-[50%]}
.col-md-offset-5 {@apply md:ml-[41.66666667%]}
.col-md-offset-4 {@apply md:ml-[33.33333333%]}
.col-md-offset-3 {@apply md:ml-[25%]}
.col-md-offset-2 {@apply md:ml-[16.66666667%]}
.col-md-offset-1 {@apply md:ml-[8.33333333%]}
.col-md-offset-0 {@apply md:ml-0}

.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12  {@apply lg:float-left;}

.col-lg-12 {@apply lg:w-full; }
.col-lg-11 {@apply lg:w-11/12; }
.col-lg-10 {@apply lg:w-10/12; }
.col-lg-9 {@apply lg:w-9/12; }
.col-lg-8 {@apply lg:w-8/12; }
.col-lg-7 {@apply lg:w-7/12; }
.col-lg-6 {@apply lg:w-6/12; }
.col-lg-5 {@apply lg:w-5/12; }
.col-lg-4 {@apply lg:w-4/12; }
.col-lg-3 {@apply lg:w-3/12; }
.col-lg-2 {@apply lg:w-2/12; }
.col-lg-1 {@apply lg:w-1/12; }
.col-lg-offset-12 {@apply lg:ml-[100%]}
.col-lg-offset-11 {@apply lg:ml-[91.66666667%]}
.col-lg-offset-10 {@apply lg:ml-[83.33333333%]}
.col-lg-offset-9 {@apply lg:ml-[75%]}
.col-lg-offset-8 {@apply lg:ml-[66.66666667%]}
.col-lg-offset-7 {@apply lg:ml-[58.33333333%]}
.col-lg-offset-6 {@apply lg:ml-[50%]}
.col-lg-offset-5 {@apply lg:ml-[41.66666667%]}
.col-lg-offset-4 {@apply lg:ml-[33.33333333%]}
.col-lg-offset-3 {@apply lg:ml-[25%]}
.col-lg-offset-2 {@apply lg:ml-[16.66666667%]}
.col-lg-offset-1 {@apply lg:ml-[8.33333333%]}
.col-lg-offset-0 {@apply lg:ml-0}

@media (min-width: 640px) {
  .col-sm-push-8 {
    left: 66.66666667%
  }

  .col-sm-pull-4 {
    right: 33.33333333%
  }
}

/* Bootstrap */
.my-highlighter {
  background: linear-gradient(90deg,hsla(24,20%,50%,.1) 70%,hsla(24,20%,50%,0));
  width: 100%;
  margin-left: -55px;
  height: 20px;
  color: #999
}

.my-highlighter,.my-highlighter2 {
  position: absolute;
  top: -100px;
  text-align: right;
  padding-right: 20px
}

.my-highlighter2 {
  background: linear-gradient(90deg,hsla(24,20%,50%,.2) 70%,hsla(24,20%,50%,.2));
  width: 94.7%;
  margin-left: -11px;
  height: 21px;
  color: #ff0;
  pointer-events: none
}

pre {
  display: block;
  padding: 10px;
  margin: 0 0 10.5px;
  font-size: 14px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #7b8a8b;
  background-color: #ecf0f1;
  border: 1px solid #ccc;
  border-radius: 4px
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0
}

pre.white-bg {
  background: #fff;
  color: #333
}

@media (max-width: 767px) {
  .hidden-xs {
      display:none!important
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left:-5px
}

.list-inline > li {
  display:inline-block;
  padding-left:5px;
  padding-right:5px
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #2c3e50;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dce4ec;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
}

.form-control:focus {
  border-color: #2c3e50;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(44,62,80,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(44,62,80,0.6)
}

.form-control::-moz-placeholder {
  color: #acb6c0;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #acb6c0
}

.form-control::-webkit-input-placeholder {
  color: #acb6c0
}

.form-control[disabled],.form-control[readonly],fieldset[disabled] .form-control {
  background-color: #ecf0f1;
  opacity: 1
}

.form-control[disabled],fieldset[disabled] .form-control {
  cursor: not-allowed
}

textarea.form-control {
  height: auto
}

input[type="search"] {
  -webkit-appearance: none
}

input[type=range] {
  display: block;
  width: 100%
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,input[type="time"].form-control,input[type="datetime-local"].form-control,input[type="month"].form-control {
      line-height:45px
  }

  input[type="date"].input-sm,input[type="time"].input-sm,input[type="datetime-local"].input-sm,input[type="month"].input-sm,.input-group-sm input[type="date"],.input-group-sm input[type="time"],.input-group-sm input[type="datetime-local"],.input-group-sm input[type="month"] {
      line-height: 35px
  }

  input[type="date"].input-lg,input[type="time"].input-lg,input[type="datetime-local"].input-lg,input[type="month"].input-lg,.input-group-lg input[type="date"],.input-group-lg input[type="time"],.input-group-lg input[type="datetime-local"],.input-group-lg input[type="month"] {
      line-height: 66px
  }
}

.form-group {
  margin-bottom: 15px
}

.form-control,input {
  border-width: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#assigned-video .form-control, #assigned-video input{
  border-width: 0px;
  border-radius: 100px;
}

.form-control:focus,input:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

/* Bootstrap form-inline styles */
@media (min-width: 768px) {
  .form-inline .form-group {
      display:inline-block;
      margin-bottom: 0;
      vertical-align: middle
  }

  .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle
  }

  .form-inline .form-control-static {
      display: inline-block
  }

  .form-inline .input-group {
      display: inline-table;
      vertical-align: middle
  }

  .form-inline .input-group .form-control,.form-inline .input-group .input-group-addon,.form-inline .input-group .input-group-btn {
      width: auto
  }

  .form-inline .input-group>.form-control {
      width: 100%
  }

  .form-inline .control-label {
      margin-bottom: 0;
      vertical-align: middle
  }

  .form-inline .checkbox,.form-inline .radio {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle
  }

  .form-inline .checkbox label,.form-inline .radio label {
      padding-left: 0
  }

  .form-inline .checkbox input[type=checkbox],.form-inline .radio input[type=radio] {
      position: relative;
      margin-left: 0
  }

  .form-inline .has-feedback .form-control-feedback {
      top: 0
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 21px
}

.table>thead>tr>th,.table>tbody>tr>th,.table>tfoot>tr>th,.table>thead>tr>td,.table>tbody>tr>td,.table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ecf0f1
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ecf0f1
}

.table>caption+thead>tr:first-child>th,.table>colgroup+thead>tr:first-child>th,.table>thead:first-child>tr:first-child>th,.table>caption+thead>tr:first-child>td,.table>colgroup+thead>tr:first-child>td,.table>thead:first-child>tr:first-child>td {
  border-top: 0
}

.table>tbody+tbody {
  border-top: 2px solid #ecf0f1
}

.table .table {
  background-color: #ffffff
}

.table-condensed>thead>tr>th,.table-condensed>tbody>tr>th,.table-condensed>tfoot>tr>th,.table-condensed>thead>tr>td,.table-condensed>tbody>tr>td,.table-condensed>tfoot>tr>td {
  padding: 5px
}

table>thead>tr>th,.table>thead>tr>th,table>tbody>tr>th,.table>tbody>tr>th,table>tfoot>tr>th,.table>tfoot>tr>th,table>thead>tr>td,.table>thead>tr>td,table>tbody>tr>td,.table>tbody>tr>td,table>tfoot>tr>td,.table>tfoot>tr>td {
  border: none
}

.table-bordered {
  border: 1px solid #ecf0f1
}

.table-bordered>thead>tr>th,.table-bordered>tbody>tr>th,.table-bordered>tfoot>tr>th,.table-bordered>thead>tr>td,.table-bordered>tbody>tr>td,.table-bordered>tfoot>tr>td {
  border: 1px solid #ecf0f1
}

.table-bordered>thead>tr>th,.table-bordered>thead>tr>td {
  border-bottom-width: 2px
}

/* .table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
} */

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9
}

.table-hover>tbody>tr:hover {
  background-color: #ecf0f1
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column
}

table td[class*="col-"],table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell
}

.table>thead>tr>td.active,.table>tbody>tr>td.active,.table>tfoot>tr>td.active,.table>thead>tr>th.active,.table>tbody>tr>th.active,.table>tfoot>tr>th.active,.table>thead>tr.active>td,.table>tbody>tr.active>td,.table>tfoot>tr.active>td,.table>thead>tr.active>th,.table>tbody>tr.active>th,.table>tfoot>tr.active>th {
  background-color: #ecf0f1
}

.table-hover>tbody>tr>td.active:hover,.table-hover>tbody>tr>th.active:hover,.table-hover>tbody>tr.active:hover>td,.table-hover>tbody>tr:hover>.active,.table-hover>tbody>tr.active:hover>th {
  background-color: #dde4e6
}

.table>thead>tr>td.success,.table>tbody>tr>td.success,.table>tfoot>tr>td.success,.table>thead>tr>th.success,.table>tbody>tr>th.success,.table>tfoot>tr>th.success,.table>thead>tr.success>td,.table>tbody>tr.success>td,.table>tfoot>tr.success>td,.table>thead>tr.success>th,.table>tbody>tr.success>th,.table>tfoot>tr.success>th {
  background-color: #18bc9c
}

.table-hover>tbody>tr>td.success:hover,.table-hover>tbody>tr>th.success:hover,.table-hover>tbody>tr.success:hover>td,.table-hover>tbody>tr:hover>.success,.table-hover>tbody>tr.success:hover>th {
  background-color: #15a589
}

.table>thead>tr>td.info,.table>tbody>tr>td.info,.table>tfoot>tr>td.info,.table>thead>tr>th.info,.table>tbody>tr>th.info,.table>tfoot>tr>th.info,.table>thead>tr.info>td,.table>tbody>tr.info>td,.table>tfoot>tr.info>td,.table>thead>tr.info>th,.table>tbody>tr.info>th,.table>tfoot>tr.info>th {
  background-color: #3498db
}

.table-hover>tbody>tr>td.info:hover,.table-hover>tbody>tr>th.info:hover,.table-hover>tbody>tr.info:hover>td,.table-hover>tbody>tr:hover>.info,.table-hover>tbody>tr.info:hover>th {
  background-color: #258cd1
}

.table>thead>tr>td.warning,.table>tbody>tr>td.warning,.table>tfoot>tr>td.warning,.table>thead>tr>th.warning,.table>tbody>tr>th.warning,.table>tfoot>tr>th.warning,.table>thead>tr.warning>td,.table>tbody>tr.warning>td,.table>tfoot>tr.warning>td,.table>thead>tr.warning>th,.table>tbody>tr.warning>th,.table>tfoot>tr.warning>th {
  background-color: #f39c12
}

.table-hover>tbody>tr>td.warning:hover,.table-hover>tbody>tr>th.warning:hover,.table-hover>tbody>tr.warning:hover>td,.table-hover>tbody>tr:hover>.warning,.table-hover>tbody>tr.warning:hover>th {
  background-color: #e08e0b
}

.table>thead>tr>td.danger,.table>tbody>tr>td.danger,.table>tfoot>tr>td.danger,.table>thead>tr>th.danger,.table>tbody>tr>th.danger,.table>tfoot>tr>th.danger,.table>thead>tr.danger>td,.table>tbody>tr.danger>td,.table>tfoot>tr.danger>td,.table>thead>tr.danger>th,.table>tbody>tr.danger>th,.table>tfoot>tr.danger>th {
  background-color: #e74c3c
}

.table-hover>tbody>tr>td.danger:hover,.table-hover>tbody>tr>th.danger:hover,.table-hover>tbody>tr.danger:hover>td,.table-hover>tbody>tr:hover>.danger,.table-hover>tbody>tr.danger:hover>th {
  background-color: #e43725
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
  border: 0;
}

/* Note: changed max-width from 767px, which matches bootstrap sm, to 639, which matches tailwind sm */
@media screen and (max-width: 639px) {
  .table-responsive {
      width:100%;
      margin-bottom: 15.75px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: none;
  }

  .table-responsive>.table {
      margin-bottom: 0
  }

  .table-responsive>.table>thead>tr>th,.table-responsive>.table>tbody>tr>th,.table-responsive>.table>tfoot>tr>th,.table-responsive>.table>thead>tr>td,.table-responsive>.table>tbody>tr>td,.table-responsive>.table>tfoot>tr>td {
      white-space: nowrap
  }

  .table-responsive>.table-bordered {
      border: 0
  }

  .table-responsive>.table-bordered>thead>tr>th:first-child,.table-responsive>.table-bordered>tbody>tr>th:first-child,.table-responsive>.table-bordered>tfoot>tr>th:first-child,.table-responsive>.table-bordered>thead>tr>td:first-child,.table-responsive>.table-bordered>tbody>tr>td:first-child,.table-responsive>.table-bordered>tfoot>tr>td:first-child {
      border-left: 0
  }

  .table-responsive>.table-bordered>thead>tr>th:last-child,.table-responsive>.table-bordered>tbody>tr>th:last-child,.table-responsive>.table-bordered>tfoot>tr>th:last-child,.table-responsive>.table-bordered>thead>tr>td:last-child,.table-responsive>.table-bordered>tbody>tr>td:last-child,.table-responsive>.table-bordered>tfoot>tr>td:last-child {
      border-right: 0
  }

  .table-responsive>.table-bordered>tbody>tr:last-child>th,.table-responsive>.table-bordered>tfoot>tr:last-child>th,.table-responsive>.table-bordered>tbody>tr:last-child>td,.table-responsive>.table-bordered>tfoot>tr:last-child>td {
      border-bottom: 0
  }
}

.nowrap{
  white-space: nowrap;
  display:inline-block;
}

/* Bootstrap visibility */
.visible-xs,.visible-sm,.visible-md,.visible-lg {
  display: none !important
}

.visible-xs-block,.visible-xs-inline,.visible-xs-inline-block,.visible-sm-block,.visible-sm-inline,.visible-sm-inline-block,.visible-md-block,.visible-md-inline,.visible-md-inline-block,.visible-lg-block,.visible-lg-inline,.visible-lg-inline-block {
  display: none !important
}

@media (max-width: 767px) {
  .visible-xs {
      display:block !important
  }

  table.visible-xs {
      display: table !important
  }

  tr.visible-xs {
      display: table-row !important
  }

  th.visible-xs,td.visible-xs {
      display: table-cell !important
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
      display:block !important
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
      display:inline !important
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
      display:inline-block !important
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .visible-sm {
      display:block !important
  }

  table.visible-sm {
      display: table !important
  }

  tr.visible-sm {
      display: table-row !important
  }

  th.visible-sm,td.visible-sm {
      display: table-cell !important
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .visible-sm-block {
      display:block !important
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .visible-sm-inline {
      display:inline !important
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .visible-sm-inline-block {
      display:inline-block !important
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .visible-md {
      display:block !important
  }

  table.visible-md {
      display: table !important
  }

  tr.visible-md {
      display: table-row !important
  }

  th.visible-md,td.visible-md {
      display: table-cell !important
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .visible-md-block {
      display:block !important
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .visible-md-inline {
      display:inline !important
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .visible-md-inline-block {
      display:inline-block !important
  }
}

@media (min-width: 1200px) {
  .visible-lg {
      display:block !important
  }

  table.visible-lg {
      display: table !important
  }

  tr.visible-lg {
      display: table-row !important
  }

  th.visible-lg,td.visible-lg {
      display: table-cell !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
      display:block !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
      display:inline !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
      display:inline-block !important
  }
}

@media (max-width: 767px) {
  .hidden-xs {
      display:none !important
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .hidden-sm {
      display:none !important
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .hidden-md {
      display:none !important
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
      display:none !important
  }
}

.visible-print {
  display: none !important
}

@media print {
  .visible-print {
      display: block !important
  }

  table.visible-print {
      display: table !important
  }

  tr.visible-print {
      display: table-row !important
  }

  th.visible-print,td.visible-print {
      display: table-cell !important
  }
}

.visible-print-block {
  display: none !important
}

@media print {
  .visible-print-block {
      display: block !important
  }
}

.visible-print-inline {
  display: none !important
}

@media print {
  .visible-print-inline {
      display: inline !important
  }
}

.visible-print-inline-block {
  display: none !important
}

@media print {
  .visible-print-inline-block {
      display: inline-block !important
  }
}

@media print {
  .hidden-print {
      display: none !important
  }
}

/* Bootstrap list-group */
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

/* angular override to list-group-item */
.list-group-item:hover {
	background-color:#fafafa;
}

/* custom legacy styles */
foreignObject {
  overflow: visible;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  
  -moz-user-select: none;
  /* Firefox all */
  
  -ms-user-select: none;
}

/* Bootstrap Glyphicons */
@font-face { 
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg')
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.glyphicon-asterisk:before {
  content: "\2a"
}

.glyphicon-plus:before {
  content: "\2b"
}

.glyphicon-eur:before,.glyphicon-euro:before {
  content: "\20ac"
}

.glyphicon-minus:before {
  content: "\2212"
}

.glyphicon-cloud:before {
  content: "\2601"
}

.glyphicon-envelope:before {
  content: "\2709"
}

.glyphicon-pencil:before {
  content: "\270f"
}

.glyphicon-glass:before {
  content: "\e001"
}

.glyphicon-music:before {
  content: "\e002"
}

.glyphicon-search:before {
  content: "\e003"
}

.glyphicon-heart:before {
  content: "\e005"
}

.glyphicon-star:before {
  content: "\e006"
}

.glyphicon-star-empty:before {
  content: "\e007"
}

.glyphicon-user:before {
  content: "\e008"
}

.glyphicon-film:before {
  content: "\e009"
}

.glyphicon-th-large:before {
  content: "\e010"
}

.glyphicon-th:before {
  content: "\e011"
}

.glyphicon-th-list:before {
  content: "\e012"
}

.glyphicon-ok:before {
  content: "\e013"
}

.glyphicon-remove:before {
  content: "\e014"
}

.glyphicon-zoom-in:before {
  content: "\e015"
}

.glyphicon-zoom-out:before {
  content: "\e016"
}

.glyphicon-off:before {
  content: "\e017"
}

.glyphicon-signal:before {
  content: "\e018"
}

.glyphicon-cog:before {
  content: "\e019"
}

.glyphicon-trash:before {
  content: "\e020"
}

.glyphicon-home:before {
  content: "\e021"
}

.glyphicon-file:before {
  content: "\e022"
}

.glyphicon-time:before {
  content: "\e023"
}

.glyphicon-road:before {
  content: "\e024"
}

.glyphicon-download-alt:before {
  content: "\e025"
}

.glyphicon-download:before {
  content: "\e026"
}

.glyphicon-upload:before {
  content: "\e027"
}

.glyphicon-inbox:before {
  content: "\e028"
}

.glyphicon-play-circle:before {
  content: "\e029"
}

.glyphicon-repeat:before {
  content: "\e030"
}

.glyphicon-refresh:before {
  content: "\e031"
}

.glyphicon-list-alt:before {
  content: "\e032"
}

.glyphicon-lock:before {
  content: "\e033"
}

.glyphicon-flag:before {
  content: "\e034"
}

.glyphicon-headphones:before {
  content: "\e035"
}

.glyphicon-volume-off:before {
  content: "\e036"
}

.glyphicon-volume-down:before {
  content: "\e037"
}

.glyphicon-volume-up:before {
  content: "\e038"
}

.glyphicon-qrcode:before {
  content: "\e039"
}

.glyphicon-barcode:before {
  content: "\e040"
}

.glyphicon-tag:before {
  content: "\e041"
}

.glyphicon-tags:before {
  content: "\e042"
}

.glyphicon-book:before {
  content: "\e043"
}

.glyphicon-bookmark:before {
  content: "\e044"
}

.glyphicon-print:before {
  content: "\e045"
}

.glyphicon-camera:before {
  content: "\e046"
}

.glyphicon-font:before {
  content: "\e047"
}

.glyphicon-bold:before {
  content: "\e048"
}

.glyphicon-italic:before {
  content: "\e049"
}

.glyphicon-text-height:before {
  content: "\e050"
}

.glyphicon-text-width:before {
  content: "\e051"
}

.glyphicon-align-left:before {
  content: "\e052"
}

.glyphicon-align-center:before {
  content: "\e053"
}

.glyphicon-align-right:before {
  content: "\e054"
}

.glyphicon-align-justify:before {
  content: "\e055"
}

.glyphicon-list:before {
  content: "\e056"
}

.glyphicon-indent-left:before {
  content: "\e057"
}

.glyphicon-indent-right:before {
  content: "\e058"
}

.glyphicon-facetime-video:before {
  content: "\e059"
}

.glyphicon-picture:before {
  content: "\e060"
}

.glyphicon-map-marker:before {
  content: "\e062"
}

.glyphicon-adjust:before {
  content: "\e063"
}

.glyphicon-tint:before {
  content: "\e064"
}

.glyphicon-edit:before {
  content: "\e065"
}

.glyphicon-share:before {
  content: "\e066"
}

.glyphicon-check:before {
  content: "\e067"
}

.glyphicon-move:before {
  content: "\e068"
}

.glyphicon-step-backward:before {
  content: "\e069"
}

.glyphicon-fast-backward:before {
  content: "\e070"
}

.glyphicon-backward:before {
  content: "\e071"
}

.glyphicon-play:before {
  content: "\e072"
}

.glyphicon-pause:before {
  content: "\e073"
}

.glyphicon-stop:before {
  content: "\e074"
}

.glyphicon-forward:before {
  content: "\e075"
}

.glyphicon-fast-forward:before {
  content: "\e076"
}

.glyphicon-step-forward:before {
  content: "\e077"
}

.glyphicon-eject:before {
  content: "\e078"
}

.glyphicon-chevron-left:before {
  content: "\e079"
}

.glyphicon-chevron-right:before {
  content: "\e080"
}

.glyphicon-plus-sign:before {
  content: "\e081"
}

.glyphicon-minus-sign:before {
  content: "\e082"
}

.glyphicon-remove-sign:before {
  content: "\e083"
}

.glyphicon-ok-sign:before {
  content: "\e084"
}

.glyphicon-question-sign:before {
  content: "\e085"
}

.glyphicon-info-sign:before {
  content: "\e086"
}

.glyphicon-screenshot:before {
  content: "\e087"
}

.glyphicon-remove-circle:before {
  content: "\e088"
}

.glyphicon-ok-circle:before {
  content: "\e089"
}

.glyphicon-ban-circle:before {
  content: "\e090"
}

.glyphicon-arrow-left:before {
  content: "\e091"
}

.glyphicon-arrow-right:before {
  content: "\e092"
}

.glyphicon-arrow-up:before {
  content: "\e093"
}

.glyphicon-arrow-down:before {
  content: "\e094"
}

.glyphicon-share-alt:before {
  content: "\e095"
}

.glyphicon-resize-full:before {
  content: "\e096"
}

.glyphicon-resize-small:before {
  content: "\e097"
}

.glyphicon-exclamation-sign:before {
  content: "\e101"
}

.glyphicon-gift:before {
  content: "\e102"
}

.glyphicon-leaf:before {
  content: "\e103"
}

.glyphicon-fire:before {
  content: "\e104"
}

.glyphicon-eye-open:before {
  content: "\e105"
}

.glyphicon-eye-close:before {
  content: "\e106"
}

.glyphicon-warning-sign:before {
  content: "\e107"
}

.glyphicon-plane:before {
  content: "\e108"
}

.glyphicon-calendar:before {
  content: "\e109"
}

.glyphicon-random:before {
  content: "\e110"
}

.glyphicon-comment:before {
  content: "\e111"
}

.glyphicon-magnet:before {
  content: "\e112"
}

.glyphicon-chevron-up:before {
  content: "\e113"
}

.glyphicon-chevron-down:before {
  content: "\e114"
}

.glyphicon-retweet:before {
  content: "\e115"
}

.glyphicon-shopping-cart:before {
  content: "\e116"
}

.glyphicon-folder-close:before {
  content: "\e117"
}

.glyphicon-folder-open:before {
  content: "\e118"
}

.glyphicon-resize-vertical:before {
  content: "\e119"
}

.glyphicon-resize-horizontal:before {
  content: "\e120"
}

.glyphicon-hdd:before {
  content: "\e121"
}

.glyphicon-bullhorn:before {
  content: "\e122"
}

.glyphicon-bell:before {
  content: "\e123"
}

.glyphicon-certificate:before {
  content: "\e124"
}

.glyphicon-thumbs-up:before {
  content: "\e125"
}

.glyphicon-thumbs-down:before {
  content: "\e126"
}

.glyphicon-hand-right:before {
  content: "\e127"
}

.glyphicon-hand-left:before {
  content: "\e128"
}

.glyphicon-hand-up:before {
  content: "\e129"
}

.glyphicon-hand-down:before {
  content: "\e130"
}

.glyphicon-circle-arrow-right:before {
  content: "\e131"
}

.glyphicon-circle-arrow-left:before {
  content: "\e132"
}

.glyphicon-circle-arrow-up:before {
  content: "\e133"
}

.glyphicon-circle-arrow-down:before {
  content: "\e134"
}

.glyphicon-globe:before {
  content: "\e135"
}

.glyphicon-wrench:before {
  content: "\e136"
}

.glyphicon-tasks:before {
  content: "\e137"
}

.glyphicon-filter:before {
  content: "\e138"
}

.glyphicon-briefcase:before {
  content: "\e139"
}

.glyphicon-fullscreen:before {
  content: "\e140"
}

.glyphicon-dashboard:before {
  content: "\e141"
}

.glyphicon-paperclip:before {
  content: "\e142"
}

.glyphicon-heart-empty:before {
  content: "\e143"
}

.glyphicon-link:before {
  content: "\e144"
}

.glyphicon-phone:before {
  content: "\e145"
}

.glyphicon-pushpin:before {
  content: "\e146"
}

.glyphicon-usd:before {
  content: "\e148"
}

.glyphicon-gbp:before {
  content: "\e149"
}

.glyphicon-sort:before {
  content: "\e150"
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151"
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"
}

.glyphicon-sort-by-order:before {
  content: "\e153"
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154"
}

.glyphicon-sort-by-attributes:before {
  content: "\e155"
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"
}

.glyphicon-unchecked:before {
  content: "\e157"
}

.glyphicon-expand:before {
  content: "\e158"
}

.glyphicon-collapse-down:before {
  content: "\e159"
}

.glyphicon-collapse-up:before {
  content: "\e160"
}

.glyphicon-log-in:before {
  content: "\e161"
}

.glyphicon-flash:before {
  content: "\e162"
}

.glyphicon-log-out:before {
  content: "\e163"
}

.glyphicon-new-window:before {
  content: "\e164"
}

.glyphicon-record:before {
  content: "\e165"
}

.glyphicon-save:before {
  content: "\e166"
}

.glyphicon-open:before {
  content: "\e167"
}

.glyphicon-saved:before {
  content: "\e168"
}

.glyphicon-import:before {
  content: "\e169"
}

.glyphicon-export:before {
  content: "\e170"
}

.glyphicon-send:before {
  content: "\e171"
}

.glyphicon-floppy-disk:before {
  content: "\e172"
}

.glyphicon-floppy-saved:before {
  content: "\e173"
}

.glyphicon-floppy-remove:before {
  content: "\e174"
}

.glyphicon-floppy-save:before {
  content: "\e175"
}

.glyphicon-floppy-open:before {
  content: "\e176"
}

.glyphicon-credit-card:before {
  content: "\e177"
}

.glyphicon-transfer:before {
  content: "\e178"
}

.glyphicon-cutlery:before {
  content: "\e179"
}

.glyphicon-header:before {
  content: "\e180"
}

.glyphicon-compressed:before {
  content: "\e181"
}

.glyphicon-earphone:before {
  content: "\e182"
}

.glyphicon-phone-alt:before {
  content: "\e183"
}

.glyphicon-tower:before {
  content: "\e184"
}

.glyphicon-stats:before {
  content: "\e185"
}

.glyphicon-sd-video:before {
  content: "\e186"
}

.glyphicon-hd-video:before {
  content: "\e187"
}

.glyphicon-subtitles:before {
  content: "\e188"
}

.glyphicon-sound-stereo:before {
  content: "\e189"
}

.glyphicon-sound-dolby:before {
  content: "\e190"
}

.glyphicon-sound-5-1:before {
  content: "\e191"
}

.glyphicon-sound-6-1:before {
  content: "\e192"
}

.glyphicon-sound-7-1:before {
  content: "\e193"
}

.glyphicon-copyright-mark:before {
  content: "\e194"
}

.glyphicon-registration-mark:before {
  content: "\e195"
}

.glyphicon-cloud-download:before {
  content: "\e197"
}

.glyphicon-cloud-upload:before {
  content: "\e198"
}

.glyphicon-tree-conifer:before {
  content: "\e199"
}

.glyphicon-tree-deciduous:before {
  content: "\e200"
}

.glyphicon-cd:before {
  content: "\e201"
}

.glyphicon-save-file:before {
  content: "\e202"
}

.glyphicon-open-file:before {
  content: "\e203"
}

.glyphicon-level-up:before {
  content: "\e204"
}

.glyphicon-copy:before {
  content: "\e205"
}

.glyphicon-paste:before {
  content: "\e206"
}

.glyphicon-alert:before {
  content: "\e209"
}

.glyphicon-equalizer:before {
  content: "\e210"
}

.glyphicon-king:before {
  content: "\e211"
}

.glyphicon-queen:before {
  content: "\e212"
}

.glyphicon-pawn:before {
  content: "\e213"
}

.glyphicon-bishop:before {
  content: "\e214"
}

.glyphicon-knight:before {
  content: "\e215"
}

.glyphicon-baby-formula:before {
  content: "\e216"
}

.glyphicon-tent:before {
  content: "\26fa"
}

.glyphicon-blackboard:before {
  content: "\e218"
}

.glyphicon-bed:before {
  content: "\e219"
}

.glyphicon-apple:before {
  content: "\f8ff"
}

.glyphicon-erase:before {
  content: "\e221"
}

.glyphicon-hourglass:before {
  content: "\231b"
}

.glyphicon-lamp:before {
  content: "\e223"
}

.glyphicon-duplicate:before {
  content: "\e224"
}

.glyphicon-piggy-bank:before {
  content: "\e225"
}

.glyphicon-scissors:before {
  content: "\e226"
}

.glyphicon-bitcoin:before,.glyphicon-btc:before,.glyphicon-xbt:before {
  content: "\e227"
}

.glyphicon-jpy:before,.glyphicon-yen:before {
  content: "\00a5"
}

.glyphicon-rub:before,.glyphicon-ruble:before {
  content: "\20bd"
}

.glyphicon-scale:before {
  content: "\e230"
}

.glyphicon-ice-lolly:before {
  content: "\e231"
}

.glyphicon-ice-lolly-tasted:before {
  content: "\e232"
}

.glyphicon-education:before {
  content: "\e233"
}

.glyphicon-option-horizontal:before {
  content: "\e234"
}

.glyphicon-option-vertical:before {
  content: "\e235"
}

.glyphicon-menu-hamburger:before {
  content: "\e236"
}

.glyphicon-modal-window:before {
  content: "\e237"
}

.glyphicon-oil:before {
  content: "\e238"
}

.glyphicon-grain:before {
  content: "\e239"
}

.glyphicon-sunglasses:before {
  content: "\e240"
}

.glyphicon-text-size:before {
  content: "\e241"
}

.glyphicon-text-color:before {
  content: "\e242"
}

.glyphicon-text-background:before {
  content: "\e243"
}

.glyphicon-object-align-top:before {
  content: "\e244"
}

.glyphicon-object-align-bottom:before {
  content: "\e245"
}

.glyphicon-object-align-horizontal:before {
  content: "\e246"
}

.glyphicon-object-align-left:before {
  content: "\e247"
}

.glyphicon-object-align-vertical:before {
  content: "\e248"
}

.glyphicon-object-align-right:before {
  content: "\e249"
}

.glyphicon-triangle-right:before {
  content: "\e250"
}

.glyphicon-triangle-left:before {
  content: "\e251"
}

.glyphicon-triangle-bottom:before {
  content: "\e252"
}

.glyphicon-triangle-top:before {
  content: "\e253"
}

.glyphicon-console:before {
  content: "\e254"
}

.glyphicon-superscript:before {
  content: "\e255"
}

.glyphicon-subscript:before {
  content: "\e256"
}

.glyphicon-menu-left:before {
  content: "\e257"
}

.glyphicon-menu-right:before {
  content: "\e258"
}

.glyphicon-menu-down:before {
  content: "\e259"
}

.glyphicon-menu-up:before {
  content: "\e260"
}


/** START problem.component.css **/

.display-problem, .display-problem h3, .display-problem h4, .display-problem h5, .display-problem h6, .display-problem h1, .display-problem h2, .display-problem p {
  font-family: georgia, Serif, Symbola; 
}

.display-problem input{ /* custom-answer-template */
  width:32px;
  text-align: center;
  padding: 1px 2px;
}


/* div.MathJax_Display {
  margin: 1em 0;
  display: inline;
}

.right-equation div.MathJax_Display {
  text-align: left;
  display: inline;
}

.left-equation div.MathJax_Display {
  text-align: right;
  display: inline;
} */

.left-equation .katex-display {
  text-align: right;
}

.right-equation .katex-display {
  text-align: left;
}

.line-equation .katex-display,
.line-type .katex-display {
  overflow-x: visible;
  margin-top: 0;
  margin-bottom: 0;
}

/* so my resizer knows the exact width of the katex */
.katex-display>.katex { 
  display:inline-block;
}

.katex {
  white-space: nowrap;
}

/* for piecewise functions, overriding katex to prevent oddly sized svg - TODO: need to test for other delims! */
.katex .delimsizing.mult .vlist svg {
  width: 100% !important;
}

/* prevents mathml from being read by screenreader, since we are using the render-a11y-string plug-in instead of mathml */
.katex .katex-mathml {
  display: none;
}

#problem_page.display-problem,
#question_page.display-problem, 
problem-page.display-problem, 
timed-page.display-problem,
.dialog-solution-page,
.answerArea {
  font-size: 1.3125em;
}

select.form-control {appearance: auto;}

/* React styles for custom select (dropdowns), if the form-control class has not been applied */
.display-problem select:not(.form-control) {
  margin-top: 0.5rem;
  border-radius: 0.375rem;
  border-width: 0px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 2.25rem;
  font-size: inherit;
  background-size: 1.25rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(221 221 221 / var(--tw-ring-opacity));
}

.display-problem select:focus:not(.form-control) {
  --tw-ring-color: rgb(47 81 233 / 1);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

/* .display-problem .form-control,
.display-problem input {
  border-width: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
  padding: 1px 2px;
}

.display-problem .form-control:focus,
.display-problem input:focus {
  -webkit-box-shadow: none;
  box-shadow: none
} */

.off-to-the-left {
  position: absolute;
  left: -20000px;
  top: -200000px;
  width: 100%;
}

.off-to-the-left2 {
  /*position: relative;*/
  position: absolute;
  left: -20000px;
}

div.row.hasJax {
  /* margin-top: 35px; */
  /*padding-left: 0%;*/
  
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

@media(max-width:767px) {
  div.row.hasJax.small-screen-space-35 {
      margin-top: 35px !important;
  }
  div.row.hasJax.small-screen-space-30 {
      margin-top: 30px !important;
  }
  div.row.hasJax.small-screen-space-25 {
      margin-top: 25px !important;
  }
  div.row.hasJax.small-screen-space-20 {
      margin-top: 20px !important;
  }
  div.row.hasJax.small-screen-space-15 {
      margin-top: 15px !important;
  }
  div.row.hasJax.small-screen-space-10 {
      margin-top: 10px !important;
  }
  div.row.hasJax.small-screen-space-5 {
      margin-top: 5px !important;
  }
  div.row.hasJax.small-screen-space-0 {
      margin-top: 0px !important;
  }
}


div.row.hasJax.explanation-size-95 .explanation{
  font-size:.95em;
}
div.row.hasJax.explanation-size-90 .explanation{
  font-size:.90em;
}
div.row.hasJax.explanation-size-85 .explanation{
  font-size:.85em;
}
div.row.hasJax.explanation-size-80 .explanation{
  font-size:.80em;
}
div.row.hasJax.explanation-size-75 .explanation{
  font-size:.75em;
}
div.row.hasJax.explanation-size-70 .explanation{
  font-size:.70em;
}
div.row.hasJax.explanation-size-65 .explanation{
  font-size:.65em;
}
div.row.hasJax.explanation-size-60 .explanation{
  font-size:.60em;
}
div.row.hasJax.explanation-size-55 .explanation{
  font-size:.55em;
}
div.row.hasJax.explanation-size-50 .explanation{
  font-size:.50em;
}


.problem-header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  
}

.problem-header {
  font-family: Handlee;
  border-bottom: 1px gray dashed;
  padding: 10px 0px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  padding-right:60px;
}

.problem-prompt {
  margin-bottom: 40px;
}

@media(max-width:500px) {
  .problem-header {
      font-size: 4vw;
  }
}

@media(max-width:380px) {
  .problem-header {
      font-size: 3.5vw;
  }
}

.problem-header-symbol {
  /*-ms-flex-self: center;
  -webkit-align-self: center;
  -webkit-box-self: center;
  align-self: center;
  margin-left: auto;
  font-size: 4em;
  margin-bottom: -30px;
  margin-right: 10px;
  margin-top: -10px;*/
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 4em;
}

.fa-check {
  color: #0a0;
}

.fa-times {
  color: #d41;
}

.explanation {
  font-size: 18px;
  /*.9em;*/
  
  position: relative;
  top: 2px;
  font-family: Handlee;
}

.line-info {
  position: relative;
  font-size: 12px;
  /*.5em;*/
  
  left: 0px;
  opacity: .4;
  margin-left: -12px;
}

.left-equation {
  padding-right: 0;
  text-align: right;
  display: inline-block;
}

.right-equation {
  padding-left: 0;
  text-align: left;
  display: inline-block;
}

.sign-equation {
  padding-left: 0;
  padding-right: 0;
}





.graph-instructions{
  /*max-width: 450px;*/
  font-size: .8em;
  color: #333;
  text-align: center;
  /* margin:auto; */
  margin-bottom: 12px;
}

.shrink{
  font-size:0.85em;
}

.responsive-font {
  font-size: 1em;
}

@media(max-width:401px){
  .responsive-font {
      font-size: 0.9em;
  }

  .graph-instructions {
      font-size: 0.72em;
  }
}

@media(max-width:380px) {
  .graph-instructions {
      font-size: 0.65em;
  }

  .responsive-font {
      font-size: 0.8em;
  }
}

@media(max-width:321px) {
  .graph-instructions {
      font-size: 0.55em;
  }

  .responsive-font {
      font-size: .7em;
  }
}






.display-problem hr {
  margin-top: 21px;
  margin-bottom: 21px;
}

.yoursolution, .answerData {
  /* border-top: 1px black dashed; */
  position: relative;
  /* padding-top: 25px; */
}

.answerData {
  top:25px;
  clear:both;
}

.answerData svg.delta-graph {
  background: white;
  padding: 8px;
}

.yoursolution-math {
  font-family: georgia, Serif, Symbola;
}

/* not span because that messes with KATEX */
.yoursolution-math>p{
  font-size:1.1em;
  
  text-align: left;
  margin:20px 20px;
}

.yoursolution-math>div{
  font-size:1.1em;
  text-align: center;
  margin:20px 20px;
}


.yoursolution-alerts {
  padding: 0px;
}

.yoursolution-alerts .alert {
  padding: 10px;
  margin-bottom:12px;
}

.yoursolution-label, .answerData .answerData-label {
  /* position: absolute;
  top: 0px;
  left: 0; */
  /* font-size: .8em; */
  /* background-color: rgba(44, 62, 80, .2); */
  
  /* padding: 3px; */
  /* border-left: 0px black dashed; */
  padding-bottom: 10px;
}

.answerData .answerData-label{
  /* margin-bottom:-30px; */
  margin-left:15px;
  /* padding-bottom: 0px; */
}

/* answerData-label is hidden from view in React -- this is a holdover from the Angular design where the student's work was displayed differently on the page and needed a separate label */
.answerData-label {
  display: none;
}

/* video {
  width: 100%;
  max-width: initial;
  border-bottom: 1px gray dashed;
  padding-top: 0;
} */

[mat-dialog-title].problem-solution-title {
  background: rgba(0,0,0,.1);
  margin: -24px;
  padding: 8px 24px;
  margin-bottom: 0px;
  border: 1px #ddd solid;
  border-radius: 3px;
}

.give-padding-top {
  padding-top:20px;
}

.solution-page-overlay .mat-dialog-container {
  padding-bottom: 0px;
}

.solution-page-overlay .mat-dialog-content {
  max-height: 96%;
}

.print-possible-radio {
  width: initial;
  position: relative;
  top: 3px;
}

.error-snackbar {
  background-color: indianred;
}

.error-snackbar .mat-button-wrapper {
  color: white;
}

.pdf-settings-label {
  min-width:200px;
  text-align: right;
  margin-top:20px; 
  display:inline-block;
  font-weight: normal;
}

.extra-padding-top {
  padding-top: 20px;
}

/* END problem.component.css */

/* DeltaGraph style for accessibility */
.no-click-focus:focus:not(:focus-visible) {
  outline: none;
}

/* Other styles from Angular */
table a.delta-link, a.delta-link{
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

.delta-link:hover{
    text-decoration: underline;
}

.width-100 {
  width:100%
}

/* Angular style for answer preview (custom problems) */
.verify-math {
  /* font-size: 1.5em;  */
  /* margin: -30px 0; */
  /* overflow-x: auto; */
  /* margin-top: -20px; */
  margin: 20px;
  font-family: georgia, Serif, Symbola;
}

.verify-math > p {
  /* margin: 20px;   */
  text-align: left;
}


.verify-math > div {
  /* margin: 20px; */
  text-align: center;
}

.yoursolution .verify-math {
  margin: 10px 0 0;
}

/* Angular style for answer area inputs */
.answerArea input[type="radio"] {
  /* old styles are the three lines below */
  /* height:100%;
  vertical-align: middle;
  margin-top: -1px; */
  height: 1rem;
  width: 1rem;
  border-width: 1px;
  border-color: rgb(156 163 175 / 1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  cursor: pointer;
}

.answerArea input[type="checkbox"] {
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgb(209 213 219);
  margin: 0.25rem;
}

.answer-font {
  font-family: georgia, Serif, Symbola;
}

svg:focus {
  outline:none;
  border: 0.5px #ccc solid;
}

/* styles for user created problems */
.user-created-custom-table tr:first-child td {
  background-color: rgba(44, 80, 44, 0.05);
}

.user-created-custom-table td {
  padding: 7px; 
  min-width: 100px; 
  text-align: center; 
  border: 1px black solid;
}

.user-created-custom-table input {
  width: 100px;
  text-align: center;
}

/* dragula */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}


/****************************************************
      Deltamath Components
****************************************************/

/* .dm-btn {
  @apply inline-flex justify-center rounded border border-transparent px-4 py-2 text-sm text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2;
  @apply bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600;
} */


/* Fade in sidebar menu items on scroll */
.fade-in-section {
  opacity: .25;
  transition: opacity 0.65s ease-out;
  will-change: opacity;
}
.fade-in-section.is-visible {
  opacity: 1;
}

.fade-in-badge {
  opacity: .4;
  transition: opacity 0.4s ease-out;
  will-change: opacity;
}
.fade-in-badge.is-visible {
  opacity: 1;
}

/* 
https://app.clickup.com/t/8678n5uwj

Fix for Screenshot reader chrome extension
https://chrome.google.com/webstore/detail/screenshot-reader/enfolipbjmnmleonhhebhalojdpcpdoo/related
*/
 #canv {position: static !important;}