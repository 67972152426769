@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: DejaVuSerif;
  src: url("./fonts/DejaVuSerif.ttf");
}

.braille {
  font-family: DejaVuSerif;
  /* font-family: DejaVuSerif;
    src: local(''), url(./fonts/DejaVuSerif.ttf) format('ttf'); */
}

.custom-checkbox[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  border: 2px solid black;
  position: relative;
  cursor: pointer;
}

.custom-checkbox[type="checkbox"]:checked {
  background-color: white;
  border: 2px solid #2f52e9;
  height: 1.25rem;
  width: 1.25rem;
}

.custom-checkbox[type="checkbox"]:checked:focus {
  background-color: white;
  border: 2px solid #2f52e9;
  height: 1.25rem;
  width: 1.25rem;
}

.custom-checkbox[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

.custom-checkbox[type="checkbox"]:checked:hover {
  border-color: #2f52e9;
  background-color: white;
}

.custom-checkbox[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 75%;
  background-color: #2f52e9;
}

.svg-hover:active svg path {
  stroke: white !important;
  fill: white !important;
}

.svg-hover:hover svg path {
  stroke: #2f52e9;
}

.svg-white svg path {
  stroke: white !important;
  fill: white !important;
}
